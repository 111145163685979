import styles from "./Applications.module.scss";
import {
  Button, Table, Sidebar, DashboardHeader, ExportButton, Aside, Link,
  SearchBar, FilterButton, TimeZoneMessage
} from "common";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import appActions from "reducers/AppReducer";
import popUpActions from "reducers/PopUpReducer";
import { IDropdownOption, IFilter, IHeader, IHighlight, IList } from "common/interfaces";
import { useAuth } from "auth/useAuth";
import { APP_STATUSES, OFFER_TYPE, POPUPS } from "utils/constants";
import { useParams } from "react-router-dom";
import { TableRef } from "common/Table";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import Observer, { EVENTS } from "classes/Observer";
import Analytics, { ITracking } from "classes/Analytics";
import { formatAmount, formatAPICurrency, formatAPIDate, formatAPIPhone, formatAPIString, formatFullname } from "utils/formatters";
import ApplicationSummaryCard from "./ApplicationSummaryCard";
import InnerPopups from "content/popups/InnerPopups";
import { askForConfirmation, closePopup, displayMiniFeedback } from "utils/helpers";
import { useBusinessModel } from "hooks/useBusinessModel";
import { useCooldown } from "hooks/useCooldown";
import InvitationHandler from "actions/InvitationHandler";

const Applications: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAuth()?.user;
  const { merchantSlug } = useParams();
  const selectedLocation = useAppSelector(state => state.app.selectedLocation);
  const observerLoanAppUpdated = Observer.useObserver(EVENTS.LOAN_APP_UPDATED);
  const tableFilterAppliedObserver = Observer.useObserver(EVENTS.TABLE_FILTER_APPLIED);
  const applicationFilter = useAppSelector(state => state.app.tableFilters.application);
  const observerBusinessModel = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);
  const selectedBusinessModel = useBusinessModel();
  const { countdown, lastSent, setCooldown, canResend } = useCooldown(0.5);// half a second - just enough to prevent double clicks

  const [keyword, setKeyword] = useState("");
  const [tableRows, setTableRows] = useState([]);
  const tableRef = useRef<TableRef>(null);
  const [preventPagination, setPreventPagination] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [applicationId, setApplicationId] = useState<string>("");

  const applicationSummaryRef = useRef(null);

  // partners don't have applications table at all
  // DTM - wl + merchants: name, status (without buttons), app date, apr, requested, funded, adjustments, actions
  // DTC - merchant + WL - name, status (without buttons), app date, apr, requested, max offered, funded, actions

  const applicationsHeaders: Array<IHeader> = [
    { label: "Name", value: "name", size: 11, sortBy: "first_name,last_name" },
    { label: "Status", value: "statusStyled", size: 9, sortBy: "status" },
    { label: "App date", value: "appdate", size: 8, sortBy: "created_at" },
    { label: "APR", value: "apr", size: 7, sortBy: "min_apr" },
    { label: "Requested", value: "requested", size: 8, sortBy: "loan_amount" },
  ];
  if (selectedBusinessModel === "DTM") {
    applicationsHeaders.push({ label: "Funded", value: "funded", size: 8, sortBy: "funded_amount" });
    // we don't have adjustments yet
    applicationsHeaders.push({ label: "Adjustments", value: "adjustments", size: 9, /*sortBy: "adjustments"*/ });
    applicationsHeaders.push({ label: "Actions", value: "actions", size: 12, preventSorting: true });
  } else if (selectedBusinessModel === "DTC") {
    applicationsHeaders.push({ label: "Max offered", value: "max_offered", size: 8, sortBy: "max_loan_amount" });
    applicationsHeaders.push({ label: "Funded", value: "funded", size: 8, sortBy: "funded_amount" });
    applicationsHeaders.push({ label: "Actions", value: "actions", size: 12, preventSorting: true });
  }

  const handlePendingClick = async (item: any): Promise<void> => {
    const offersDetails = await getOfferDetails(item?.id);
    const offerDetails = offersDetails.results[0];

    askForConfirmation(<>
      <h1 style={{ marginBottom: 30 }}>{`Approve $${formatAmount(offerDetails?.amount)} loan for ${formatFullname(item?.first_name, item?.last_name)}?`}</h1>
      <h3 className={styles.confirmationDialog}><span className={`${styles.confirmationDialog} ${styles.primary}`}>${formatAmount(item?.loan_amount)}</span> requested amount</h3>
      <h3 className={styles.confirmationDialog}><span className={`${styles.confirmationDialog} ${styles.primary}`}>{offerDetails?.monthlyPayment}</span>/month for <span className={`${styles.confirmationDialog} ${styles.primary}`}>{offerDetails?.term}</span> {(offerDetails?.term_unit as string)?.toLowerCase()}s</h3>
      <h3 className={styles.confirmationDialog}><span className={`${styles.confirmationDialog} ${styles.primary}`}>{offerDetails?.apr_num}%</span>&nbsp;interest rate | <span className={`${styles.confirmationDialog} ${styles.primary}`}>{formatAmount(offerDetails?.dtm_extra_data?.discount_rate * 100 || 0)}%</span> discount rate</h3>
      <h3 className={styles.confirmationDialog}><span className={`${styles.confirmationDialog} ${styles.primary}`}>{offerDetails?.dtm_extra_data?.same_as_cash_length} months SAC</span>&nbsp;same as cash <span style={{ fontSize: 13 }}>(no interest if paid in full)</span></h3>
    </>,
      { text: "Approve", action: () => { approveOffer(item); closePopup(); } },
      { text: "Decline", action: () => { denyOffer(item); closePopup(); } }, false, 800);
  }

  const handleApplicationStatusClick = async (item: any): Promise<void> => {
    dispatch(popUpActions.openPopup({ name: POPUPS.APPLICATION_STATUS, message: item }));
  }

  const handleSetAmountRecalculateClick = async (item: any): Promise<void> => {
    dispatch(popUpActions.openPopup({ name: POPUPS.RECALCULATION, message: item }));
  }

  const handleLoanCancellation = (applicationDetails: any) => {
    dispatch(popUpActions.openPopup({ name: POPUPS.CANCEL_LOAN, message: applicationDetails }));
  }

  const approveOffer = async (item: any) => {
    await LoanApplicationHandler.approveOrDenyOffer(item?.id, "approve")
      .then(() => tableRef?.current?.reloadData());
  }

  const denyOffer = async (item: any) => {
    await LoanApplicationHandler.approveOrDenyOffer(item?.id, "decline")
      .then(() => tableRef?.current?.reloadData());
  }

  const getOfferDetails = async (applicantId): Promise<IList> => {
    let offerDetails = await LoanApplicationHandler.getOfferDetails(null, applicantId);

    offerDetails.results = offerDetails.results.map(result => {
      return {
        ...result,
        lender: result.lender_name,
        dateTime: formatAPIDate(result.date_time),
        offerType: result.pre_approved ? OFFER_TYPE.PRE_APPROVED : (result.pre_qualified ? OFFER_TYPE.PRE_QUALIFIED : "-"),
        termOfLoan: `${result.term} ${result.term_unit?.toLowerCase()}${result.term > 1 ? 's' : ''}`,
        monthlyPayment: formatAPICurrency(parseFloat(result.monthly_payment)),
        maxLoan: formatAPICurrency(parseFloat(result.amount)),
        apr: `${parseFloat(result.apr).toFixed(2)}% ${result.apr_type}`,
        monthly_payment: parseFloat(result.monthly_payment),
        max_loan: parseFloat(result.amount),
        apr_num: parseFloat(result.apr),
        status: result.status,
        funded_amount: parseFloat(result.funded_amount || "0"),
        fundedAmount: formatAPICurrency(parseFloat(result.funded_amount || "0")),
      }
    });
    return offerDetails;
  }

  const handleShowSummary = (item) => {
    setApplicationId(item.id);
    Analytics.track({ experience: "portal", screen: "applications", object: "application_record", action: "selected" } as ITracking, { application_id: applicationId, merchant_id: item.merchant?.id });
  }

  const getStatusStyle = (status: string): CSSProperties => {
    let color = null;
    switch (status) {
      case APP_STATUSES.APPLICATION_STARTED: color = "var(--primaryVariationColor)"; break;
      case APP_STATUSES.APPLICATION_SUBMITTED: color = "var(--primaryVariationColor)"; break;
      case APP_STATUSES.RETRIEVING_OFFERS: color = "var(--primaryVariationColor)"; break;
      case APP_STATUSES.PENDING_WITH_LENDER: color = "var(--primaryVariationColor)"; break;
      case APP_STATUSES.PENDING_WITH_MERCHANT: color = "var(--primaryVariationColor)"; break;
      case APP_STATUSES.INVITED: color = "var(--primaryVariationColor)"; break;
      case APP_STATUSES.INVITAION_RESENT: color = "var(--primaryVariationColor)"; break;

      case APP_STATUSES.INVITATION_CLICKED: color = "var(--greenColor)"; break;
      case APP_STATUSES.CLICKED: color = "var(--greenColor)"; break;
      case APP_STATUSES.OFFERED: color = "var(--greenColor)"; break;
      case APP_STATUSES.APPROVED_BY_LENDER: color = "var(--greenColor)"; break;
      case APP_STATUSES.ACCEPTED_BY_CONSUMER: color = "var(--greenColor)"; break;
      case APP_STATUSES.READY_FOR_FUNDING: color = "var(--greenColor)"; break;
      case APP_STATUSES.FUNDED: color = "var(--greenColor)"; break;

      case APP_STATUSES.DECLINED_BY_LENDER: color = "var(--alertColor)"; break;
      case APP_STATUSES.DECLINED_BY_CONSUMER: color = "var(--alertColor)"; break;
      case APP_STATUSES.DECLINED_BY_MERCHANT: color = "var(--alertColor)"; break;
      case APP_STATUSES.DELINQUENT: color = "var(--alertColor)"; break;
      case APP_STATUSES.CHARGE_OFF: color = "var(--alertColor)"; break;
      case APP_STATUSES.POTENTIAL_BUYBACK: color = "var(--alertColor)"; break;
      case APP_STATUSES.ACTIVE_DISPUTE: color = "var(--alertColor)"; break;
      case APP_STATUSES.INVITATION_EXPIRED: color = "var(--alertColor)"; break;
      case APP_STATUSES.INVITATION_CANCELED: color = "var(--alertColor)"; break;

      case APP_STATUSES.CLOSED: color = "var(--primaryTextColor)"; break;
      case APP_STATUSES.BUYBACK: color = "var(--primaryTextColor)"; break;
      case APP_STATUSES.CANCELED: color = "var(--primaryTextColor)"; break;
      case APP_STATUSES.UNABLE_TO_VERIFY: color = "var(--primaryTextColor)"; break;
      case APP_STATUSES.NO_OFFERS: color = "var(--primaryTextColor)"; break;
    }

    return {
      color
    };
  }

  const getStatus = (item: any) => {
    return <span style={getStatusStyle(item?.status)}>{item?.status}</span>
  }

  const getApplicationsData = async (next: string): Promise<IList> => {
    setLoadingData(true);
    const appFilter: IFilter = {
      ...applicationFilter,
      merchant: applicationFilter?.merchant || merchantSlug,
      location: selectedLocation?.id || null
    };
    let list = await LoanApplicationHandler.getMany(next, appFilter, preventPagination, null, true);
    const newList = {
      ...list,
      results: list.results.map(item => {
        return {
          ...item,
          name: <button className={styles.applicantName} onClick={() => { handleShowSummary(item) }}>{formatFullname(item?.first_name, item?.last_name)}</button>,
          phone_number: formatAPIPhone(item.phone_number),
          email: formatAPIString(item.email),
          location: user?.user_type === "WHITELABEL" ? <Link className="fontTextM" id={`viewMerchant_${item.merchant?.slug}`} href={`/viewMerchant/${item.merchant?.slug}/accountInformation`} linkText={formatAPIString(user?.user_type === "WHITELABEL" ? item.merchant?.name : item.location?.name)} /> : item.location?.name,
          status: item?.status,
          statusStyled: getStatus(item),
          appdate: formatAPIDate(item.created_at),
          requested: formatAPICurrency(item.loan_amount),
          funded: formatAPICurrency(item.funded_amount),
          highlight: { highlighted: (item?.status === APP_STATUSES.PENDING_WITH_MERCHANT || item?.status === APP_STATUSES.DECLINED_BY_CONSUMER || item?.status === APP_STATUSES.DELINQUENT), property: "name" } as IHighlight,
          external_id: item?.external_id || "",
          apr: item?.min_apr ? item?.min_apr + "%" : "-",
          adjustments: "None",
          max_offered: item?.max_loan_amount ? "$" + formatAmount(item?.max_loan_amount) : "-",
        }
      })
    };
    setLoadingData(false);
    return Promise.resolve(newList);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (applicationSummaryRef.current && !applicationSummaryRef.current.contains(e.target)) {
      handleCloseApplicationSummary();
    }
  };

  const handleCloseApplicationSummary = () => {
    setApplicationId("");
  }

  useEffect(() => {
    if (observerBusinessModel > 1) {
      window.location.reload();
    }
  }, [observerBusinessModel]);

  useEffect(() => {
    dispatch(appActions.updateApplicationFilter({ ...applicationFilter, location: selectedLocation?.id || null }));
  }, [selectedLocation]);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [applicationFilter, observerLoanAppUpdated, tableFilterAppliedObserver]);

  useEffect(() => {
    if (preventPagination) {
      tableRef?.current?.reloadData();
    }
  }, [preventPagination]);

  useEffect(() => {
    if (preventPagination) {
      window.DownloadCSV(tableRows, [...applicationsHeaders.filter(header => header.label !== "Actions"), { label: "External ID", value: "external_id" }] as Array<any>, "Applications.csv");
      setPreventPagination(false);
    }
  }, [tableRows]);

  useEffect(() => {
    if (keyword) {
      Analytics.track({ experience: "portal", screen: "applications", object: "search", action: "initiated" } as ITracking);
    }
  }, [keyword]);

  const exportAction = () => {
    setPreventPagination(true);
  }

  const handleHistoryClick = (data: any) => {
    dispatch(popUpActions.openPopup({ name: POPUPS.APPLICATION_HISTORY, message: data }));
  }

  const handleResendLinkClick = (data: any) => {
    if (canResend()) {
      setCooldown();
      InvitationHandler.resendApplicationInvite(Number(data.id))
        .then(() => {
          displayMiniFeedback('Email re-sent successfully');
        });
    } else {
      // displayMiniFeedback(`Please wait ${countdown} to retry`);
    }
  }

  const actions = (): IDropdownOption[] => {
    if (user?.user_type === "MERCHANT" && selectedBusinessModel === "DTM") {
      return [
        // { label: "Set amount", visible: (data: any) => { return data.status === APP_STATUSES.PENDING_WITH_MERCHANT && !data.applicant_reject_reason }, value: (data: any) => { handleSetAmountRecalculateClick(data) } },
        // after borrower clicks the offer, it goes from "Offered" to "Clicked" status - there is an edge case if merchant recalculates offers and after that borrower clicks on already opened offers, but it will show the new amount, so that's only a small issue - shouldn't happen too often
        { label: "Recalculate", visible: (data: any) => { return (data.status === APP_STATUSES.PENDING_WITH_MERCHANT) || data.status === APP_STATUSES.DECLINED_BY_CONSUMER }, value: (data: any) => { handleSetAmountRecalculateClick(data) } },
        { label: "Resend link", visible: (data: any) => { return [APP_STATUSES.APPLICATION_STARTED, APP_STATUSES.INVITATION_CLICKED, APP_STATUSES.INVITED].includes(data.status) }, value: (data: any) => { handleResendLinkClick(data) } },
        { label: "Cancel loan", visible: (data: any) => { return data.status === APP_STATUSES.APPLICATION_STARTED || data.status === APP_STATUSES.PENDING_WITH_MERCHANT || data.status === APP_STATUSES.OFFERED || data.status === APP_STATUSES.DECLINED_BY_CONSUMER }, value: (data: any) => { handleLoanCancellation(data) } },
        // { label: "Request Refund", visible: (data: any) => { return data.status === APP_STATUSES.FUNDED }, value: (data: any) => { } },
        { label: "Contact borrower", visible: (data: any) => { return data.status === APP_STATUSES.DELINQUENT }, value: (data: any) => { handleApplicationStatusClick(data) } },
        { label: "View", visible: () => { return true }, value: (data: any) => { handleShowSummary(data) } },
        { label: "History", visible: () => { return true }, value: (data: any) => { handleHistoryClick(data) } },
      ];
    } else if (user?.user_type === "MERCHANT") {
      // DTC, merchants
      return [
        { label: "Resend link", visible: (data: any) => { return [APP_STATUSES.APPLICATION_STARTED, APP_STATUSES.INVITATION_CLICKED, APP_STATUSES.INVITED].includes(data.status) }, value: (data: any) => { handleResendLinkClick(data) } },
        { label: "View", visible: () => { return true }, value: (data: any) => { handleShowSummary(data) } },
        { label: "History", visible: () => { return true }, value: (data: any) => { handleHistoryClick(data) } },
      ];
    } else {
      return [
        { label: "View", visible: () => { return true }, value: (data: any) => { handleShowSummary(data) } },
        { label: "History", visible: () => { return true }, value: (data: any) => { handleHistoryClick(data) } },
      ];
    }
  };

  const renderBody = () => {
    const body = <div className={styles.contentContainer}>
      {user?.user_type === "MERCHANT" && <Container fluid>
        <Row>
          <Col md={12} lg={7} className={styles.filterColOne}>
            <Button
              id="applications_send"
              label="Invite applicant"
              style={{ marginRight: "3.5rem" }}
              onClick={() => {
                Analytics.track({ experience: "portal", screen: "applications", object: "invite_applicant_button", action: "clicked" } as ITracking);
                dispatch(popUpActions.openPopup(POPUPS.INVITE_APPLICANT));
              }}
            />
            <Button
              id="applications_invitationStatus"
              label="Invitation status"
              variant="secondary"
              onClick={() => {
                Analytics.track({ experience: "portal", screen: "applications", object: "invitation_status_button", action: "clicked" } as ITracking);
                dispatch(popUpActions.openPopup({ name: POPUPS.INVITATION_STATUS, message: "APPLICATIONS" }));
              }}
            />
          </Col>
          <Col
            md={12}
            lg={5}
            style={{ textAlign: "right" }}
            className={styles.appBtnColTwo}>
            <ExportButton
              id="applications"
              exporting={preventPagination}
              disabled={loadingData}
              onClick={exportAction} />
          </Col>
        </Row>
      </Container>}
      <Container fluid className={styles.searchContainer}>
        <Row>
          <Col md={6} lg={6} className={styles.searchColOne}>
            <SearchBar
              id="application_search"
              placeholder="Search applicants"
              onSearch={(keyword: string) => { setKeyword(keyword); }}
              onClearClick={() => {
                Analytics.track({ experience: "portal", screen: "applications", object: "clear_search", action: "clicked" } as ITracking);
              }}
            />
          </Col>
          <Col md={6} lg={6} className={styles.searchColTwo}>
            <FilterButton
              id="application_filter"
              type="APPLICATION"
              onClick={() => {
                dispatch(popUpActions.openPopup({ name: POPUPS.TABLE_FILTER, message: { type: "APPLICATIONS" } }));
              }} />
            {user?.user_type === "WHITELABEL" && <ExportButton
              id="applications"
              exporting={preventPagination}
              disabled={loadingData}
              onClick={() => {
                exportAction();
                Analytics.track({ experience: "portal", screen: "applications", object: "export_button", action: "clicked" } as ITracking);
              }} />}
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Table
          id="applications_location"
          data={getApplicationsData}
          headers={applicationsHeaders}
          onUpdate={(rows: any) => { setTableRows(rows) }}
          tableBodyStyle={{ minWidth: selectedBusinessModel === "DTM" && user?.user_type === "WHITELABEL" ? 1300 : 1100 }}
          headerWrapperStyle={{ minWidth: selectedBusinessModel === "DTM" && user?.user_type === "WHITELABEL" ? 1300 : 1100 }}
          keyword={keyword}
          ref={tableRef}
          maxHeight={450}
          onLoadingRows={() => {
            Analytics.track({ experience: "portal", screen: "applications", object: "table_load_more_rows", action: "initiated" } as ITracking);
          }}
          onSort={(field_sorted: string) => {
            Analytics.track({ experience: "portal", screen: "applications", object: "table_sort", action: "successful" } as ITracking, { field_sorted });
          }}
          action={actions()}
          minDropdownWidth={100}// based on "Actions" - only if dropdown includes only "Histroy" and "View"
        />
      </Container>
      <TimeZoneMessage />
      <ApplicationSummaryCard ref={applicationSummaryRef} applicantId={applicationId} closeApplicationSummary={handleCloseApplicationSummary} active={applicationId ? true : false} />
    </div>;
    if (!merchantSlug) {
      return <main className={styles.applicationsPageContainer}>
        <Sidebar />
        <Aside>
          <DashboardHeader />
          <InnerPopups />
          {body}
        </Aside>
      </main>;
    } else {
      return <div style={{ paddingTop: 100, paddingLeft: 80 }}>{body}</div>;
    }
  }

  return renderBody();
};

export default Applications;
